<template>
  <div class="otp-text">
    <div v-if="props.title" class="f-body-12 otp-text__title">
      <p class="t-foreground-tertiary f-body-12" v-html="props.title" />
    </div>
    <div class="otp-text" :focused="isFocused">
      <div class="otp-text__input">
        <p-text-input
          v-model="modelValue"
          class="otp-text__text"
          type="number"
          :name="props.name"
          :disabled="props.disabled"
          :label="props.label"
          :maxlength="props.maxlength"
        >
          <template #append>
            <div class="otp-text__action">
              <p-otp-counter
                class="otp-text__action-button"
                :auto-start="false"
                :ttl="props.ttl"
                @on-code-again="emits('on-send-code')"
              />
              <transition name="icon">
                <p-icon
                  v-if="modelValue"
                  class="otp-text__action-icon"
                  color="foreground-secondary"
                  icon="circle-close"
                  @on-click="modelValue = ''"
                />
              </transition>
            </div>
          </template>
        </p-text-input>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const emits = defineEmits(['on-send-code', 'on-input'])

const isFocused = ref(false)

const modelValue = defineModel<string>('v-model', { default: '' })

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: null,
  },
  maxlength: {
    type: Number,
    default: 6,
  },
  ttl: {
    type: Number,
    default: 180,
  },
})
</script>
